$navBackground: #fff;
$navPrimary: #577fc0;
$navSecondary: #000;

$primaryThemeColor: #577fc0;
$navSecondary: #000;

$mainButtonColor: #577fc0;
$mainButtonHoverColor: #5174af;


$whiteColor: #fff;
$greyColor: #bebebe;
$redColor: #cd2c38;
$redColor2: #b52943;
$blueColor: #3375B6;