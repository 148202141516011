.notifiers {
    // margin-top: 10px;
}

.notifiers .notifier {
    padding-right: 20px;
    position: relative;
}

.notifiers .notifier.mobile-nav-controls-btn {
  padding-right: 0px;
}

.icon-holder .notifier, .icon-holder {
    cursor: pointer;
}

.notifier {
    cursor: pointer;
}

.notifier-info {
    font-size: 11px;
    position: absolute;
    top: -8px;
    left: -1px;
    vertical-align: baseline;
    text-align: center;
    z-index: 5000;
    color: #fff;
    font-weight: bold;
}


.notifier span.icon-holder {
    position: absolute;
}

.alert-badge {
    display: inline-block;
    position: absolute;
    top: -10px;
    left: -14px;
    border: 4px solid rgba(255, 255, 255, 0.6);
    width: 8px;
    height: 10px;
    border-radius: 50%;
    padding: 6px 8px;
    z-index: 4000;
}
  
  .alert-badge:before {
    -webkit-animation: not-blink 2s cubic-bezier(.165, .84, .44, 1) infinite;
    animation: not-blink 2s cubic-bezier(.165, .84, .44, 1) infinite;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  
  .alert-badge:before,
  .alert-badge:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .bg-red.alert-badge:before,
  .bg-red.alert-badge:after {
    box-shadow: inset 0 0 20px 5px hsla(0, 31%, 46%, 0.4);
  }
  
  span.alert-badge {
    display: inline-block;
  }
  
  .bg-red {
    background: #e41616;
  }
  
  .bg-notifier-red {
    background: #f44336;
  }
  
  .bg-notifier-red.alert-badge:before,
  .bg-notifier-red.alert-badge:after {
    box-shadow: inset 0 0 15px 5px hsla(0, 31%, 46%, 0.4);
  }
  
  .bg-purple {
    background: #7c5ac2;
  }
  
  .bg-blue {
    background: #38a9ff;
  }
  
  .bg-purple.alert-badge:before,
  .bg-purple.alert-badge:after {
    box-shadow: inset 0 0 20px 5px hsla(293, 31%, 46%, 0.4);
  }

  .bg-blue.alert-badge:before, 
  .bg-blue.alert-badge:after {
    box-shadow: inset 0 0 30px 15px hsla(239, 31%, 46%, 0.4);
  }


  @media (min-width:605px)  { 
    .notifiers {
      padding-right: 20px;
      display: flex;
    }

    .notifiers.mobile-notifiers {
      padding-right: 0px;
    }
  
    .nav .nav-heading {
      padding-left: 25px;
    }  
  }