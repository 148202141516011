.custom-ngtemplate-search-dialog-form {
    .mat-form-field-wrapper {
        padding-bottom: 0 !important;
    }

    .zip-field {
        width: 130px;
    }

    .user-defined-search-card {
        margin-top: -5px; 
        padding: 16px 16px 9px 16px;
        // min-width: 100%;

        .mat-card-header-text {
            display: none;
        }

        .search-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            margin: -10px -17px 0 -17px;
            padding: 0 20px;

            .search-title {
                padding-top: 10px;
            }
        }

        .basic-search-wrapper {
            width: 100%;

            .flex-grid {
                width: 100%;
            }

            .default-flex {
                max-width: 100%;

                .mat-form-field {
                    width: 100%;
                }

                .basic-custom-field-wrapper {
                    max-width: 100%;

                    .basic-custom-field-column {
                        max-width: calc(100% - 118px);

                        .basic-custom-field-column-selector {
                            min-width: 80px;
                            box-sizing: border-box;
                        }

                        .normal-col {
                            width: 100%;
                        }

                        .half-col-width {
                            width: 49%;
                            margin-right: 0;
                        }

                        .col-md-6 {
                            width: calc(49% - 10px);
                            padding-left: 10px;
                            box-sizing: border-box;
                        }

                        .col-md-4 {
                            width: calc(33% - 10px);
                            padding-left: 10px;
                            box-sizing: border-box;
                        }

                        .col-md-2 {
                            width: calc(25% - 10px);
                            padding-left: 10px;
                            box-sizing: border-box;
                        }
                    }
                }

                @media only screen and (max-width: 680px) {
                    .basic-custom-field-wrapper {
                        .basic-custom-field-column {
                            flex-wrap: wrap;
                            max-width: 100%;

                            .basic-custom-field-column-selector {
                                width: 100% !important;
                                padding: 0 !important;
                            }
                        }
                    }
                }

                &.zip-field {
                    flex-wrap: wrap;

                    .flex-wrapper.form-field {
                        font-size: 0.75rem;
                    }

                    field-type-edit {
                        .grid-field-switch {
                            .flex-wrapper .field.column {
                                .mat-form-field-wrapper {
                                    padding-bottom: 0 !important;
                                }
                            }
                        }
                    }

                    .zip-radius.flex-field {
                        padding: 0.4rem;
                    }

                    @media only screen and (min-width: 769px) {
                        field-type-edit {
                            .grid-field-switch {
                                .flex-wrapper .field.column {
                                    width: 150px;
                                }
                            }
                        }

                        .zip-radius.flex-field {
                            max-width: 130px !important;
                        }
                    }
                }

                .grid-field-switch {
                    .flex-wrapper .field.column {
                        .popup-selector-wrapper {
                            &.default-flex {
                                padding-bottom: 1.34375em !important;

                                .relate-input {
                                    min-width: 198px !important;
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (min-width: 769px) {
                .default-flex {
                    flex-basis: 33%;

                    &.zip-field {
                        flex-basis: 66%;
                    }
                }
            }
        }

        .mat-form-field-infix {
            padding: 7px 0px 10px 5px;
        }

        .mat-card-content {
            margin-top: 16px;

            .current-fields {
                padding-top: 5px;

                .current-field {
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;

                    .example-form {
                        min-width: 150px;
                        max-width: 500px;
                        width: 100%;
                    }

                    .field-selector {
                        width: 100%;
                    }                
                }

                .field-input {
                    width: 35%;
                    padding-right: 10px;
                    box-sizing: border-box;
                }

                .filter-type {
                    width: 25%;
                    padding-right: 10px;
                    box-sizing: border-box;
                }

                .field-value {
                    width: 40%;
                }

                .row.user-defined-search-row {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .normal-col {
                        width: 100%;
                    }

                    .half-col-width {
                        width: 49%;
                        margin-right: 0;
                    }

                    .col-md-6 {
                        width: calc(49% - 10px);
                        padding-left: 10px;
                        box-sizing: border-box;
                    }

                    .col-md-4 {
                        width: calc(33% - 10px);
                        padding-left: 10px;
                        box-sizing: border-box;
                    }

                    .col-md-2 {
                        width: calc(25% - 10px);
                        padding-left: 10px;
                        box-sizing: border-box;
                    }
                }
            }

            .add-field-btn-wrapper {
                align-items: center;
                cursor: pointer;
                color: #007eff;
                margin-bottom: 20px;
            }
        }

        .mat-card-actions {
            background: rgba(158, 152, 152, 0.14);
            margin: 0 -17px -10px -17px;

            .search-btns {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                padding: 2px 0px 8px;

                .mr-10 {
                    margin-right: 10px;
                    color: #777777;
                }

                .search-btn {
                    margin-right: 10px;
                }
            }
        }   

        .search-footer {
            display: flex;
            align-items: center;

            .search-type-toggle {
                padding: 0px 10px 5px 20px;
            }

            .search-mode-chooser {
                display: flex;
                font-size: .95rem;

                .radio-option {
                    padding: 0 5px;
                }
            }
        }
    }

    @media only screen and (min-width: 769px) {
        .user-defined-search-card {
            min-width: 700px;
        }
    }
}