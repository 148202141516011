.mat-menu-panel.chart-modify-menu {
    width: 100%;
    max-width: 300px !important;

    .modify-chart-wrapper {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        font-size: 14px;
        position: relative;

        .loading-shaded-spinner {
            bottom: 0 !important;
        }

        .modify-section {
            width: 100%;

            // &:first-of-type {
            //     border-bottom: 1px solid #e0e0e0;
            //     margin-bottom: 15px;
            // }

            &.give-section-bottom-border-and-margin {
                border-bottom: 1px solid #e0e0e0;
                margin-bottom: 15px;
            }

            .modify-section-title {
                color: #757575;
                // margin-bottom: 10px;
            }

            .modify-section-content {
                width: 100%;
                padding: 15px 0 0 0;
                box-sizing: border-box;

                .chart-types-list {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;

                    .chart-type-item {
                        width: calc(100% / 3);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 5px 0;
                        box-sizing: border-box;
                        border-radius: 5px;
                        cursor: pointer;

                        .type-item-image {
                            color: #000;
                        }

                        .type-item-label {
                            color: #757575;
                        }

                        &.active {
                            background: #1d7aed;

                            .type-item-image {
                                color: #fff;
                            }

                            .type-item-label {
                                color: #fff;
                            }
                        }
                    }
                }

                .modify-attributes-form {
                    width: 100%;

                    .delete-chart-btn-wrapper {
                        width: 100%;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}