.bumpout-sidebar-wrapper .bumpout-sidebar-content {
    section.pay-table-options {
        .col {
          padding: 0 3px !important;
        }
    
        .input-wrapper {
          width: 50px !important;
        }
      
        .select-wrapper {
          width: 60px !important;
        }
      }
}