body .cke_editable, .cke_editable {
  font-family: 'Source Sans Pro', sans-serif !important;
}

.customEditor {
  width: 100%;
  height: 0px;

  .cke {
    .cke_contents {
      min-height: 420px;
    }

    .cke_bottom {
      display: flex;
      align-items: center;
      background: #fff;
      // box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14),0 1px 10px 0 rgba(0,0,0,0.12),0 2px 4px -1px rgba(0,0,0,0.2);
      border-radius: 2px;
      border-bottom: 1px solid #d1d1d1;
    }
  }
}

.no-toolbar .cke_top, .no-toolbar .cke_bottom {
  display: none !important;
}

.signature-editor {
  .cke {
    .cke_contents {
      height: 150px !important;
    }
  }
}

.in-wizard {
  .cke {
    .cke_contents {
      height: 150px !important;
      min-height: 300px !important;
    }
  }
}

.in-wizard.message-wrapper {
  min-height: auto !important;
}

.ckeditor-wrapper {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  overflow: hidden;
}

.no-border.ckeditor-wrapper {
  border: 0;
}

.email-form-editor .cke {
  border: 0;
}

.ckeditor-wrapper.is-missing {
  border: 2px solid #673ab7;
}