.cards-wrapper {
    .number-cards {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        &.number-card-4-wide {
            display: grid;
            grid-template-columns: repeat(auto-fit, 95%);
            // justify-content: space-between;
            align-content: flex-start;
        }

        .number-card {
            width: 25%;
        }
    }
}

.dash-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .dash-card {
        width: 100%;

        .titles-drop.default-flex {
            flex-wrap: wrap;
        }
    }

    .card-title {
        font-size: 24px;
        color: #1199d3;
        text-transform: uppercase;
        padding: 0;
        margin: 0;
        letter-spacing: 1px;
    }
}

@media only screen and (min-width: 681px) {
    .dash-wrapper {
        .dash-card {
            width: 48%;
        }
    }
}

@media (min-width: 610px)  {
    .cards-wrapper {
        .number-cards {
            &.number-card-4-wide {
                grid-template-columns: repeat(auto-fit, 50%);
            }
        }
    }
}

@media (min-width: 961px)  {
    .cards-wrapper {
        .number-cards {
            &.number-card-4-wide {
                grid-template-columns: repeat(auto-fit, 25%);
            }
        }
    }

    .two-wide .flex-column {
        width: 50%;
    }    
}