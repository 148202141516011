.preview-panel {
    position: relative;

    .close-panel {
        position: absolute;
        top: -5px;
        left: -5px;
        z-index: 99999;
    }

    .preview-panel-header {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: transparent;
        position: absolute;
        top: -5px;
        left: -5px;
        z-index: 99999;

        .close-panel {
            position: unset;
            top: unset;
            left: unset;
            z-index: unset;
        }
    }

    .panel-spacer-for-header {
        width: 100%;
        height: 10px;
        background: #fff;
    }

    &.open-preview-panel .profile-accordions-wrapper {
        border: none !important;
    }
}

.preview-wrapper {
    .preview-header {
        background: #5788b5;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-panel {
            padding: 20px;

            .record-title {
                color: #fff;
                font-weight: 400;
                font-size: 28px;
            }            
        }

        .right-panel {
            padding-right: 10px;

            button {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .material-icons {
                    color: #fff;
                    line-height: 32px;
                    font-size: 32px;
                }
            }
        }
    }

    .preview-body {
        // padding: 20px;

        .new-profile {
            min-height: calc(100vh - 135px);
        }

        .profile-header {
            position: relative;

            .close-panel {
                position: absolute;
                top: -8px;
                left: -8px;
            }
        }
    }
}