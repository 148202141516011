@use 'sass:map';
@use '@angular/material' as mat;

// For a given hue in a palette, return the contrast color from the map of contrast palettes.
@function md-contrast($color-map, $hue, $contrast-color-map) {
    @return map-get(map-get($contrast-color-map, $color-map), $hue);
}

// Creates a map of hues to colors for a theme.
// $color-map
// $primary
// $lighter
// $darker
@function md-palette($color-map, $primary, $lighter, $darker, $contrast-color-map) {
    $result: map_merge($color-map, (
        default: map-get($color-map, $primary),
        lighter: map-get($color-map, $lighter),
        darker: map-get($color-map, $darker),
        default-contrast: md-contrast($color-map, $primary, $contrast-color-map),
        lighter-contrast: md-contrast($color-map, $lighter, $contrast-color-map),
        darker-contrast: md-contrast($color-map, $darker, $contrast-color-map)
    ));

    // For each hue in the palette, add a "-contrast" color to the map.
    @each $hue, $color in $color-map {
        $result: map_merge($result, (
            "#{$hue}-contrast": md-contrast($color-map, $hue, $contrast-color-map)
        ))
    }

    @return $result;
}

// Gets an attribute from theme components. Will return the default value if not found
@function get-theme-attribute($attribute, $default) {
    @if $attribute == '' {
        @return $default;
    }
    @return $attribute;
}

// Gets a color for a material design component.
// $color-map: a map of {key: color}.
// $hue-key: key used to lookup the color in $colorMap. Defaults to 'default'
//     If $hue-key is a number between 0 and 1, it will be treated as $opacity.
// $opacity: the opacity to apply to the color.
@function md-color($color-map, $hue-key: default, $opacity: 1) {
    // If hueKey is a number between zero and one, then it actually contains an
    // opacity value, so recall this function with the default hue and that given opacity.
    @if type-of($hue-key) == number and $hue-key >= 0 and $hue-key <= 1 {
        @return md-color($color-map, default, $hue-key)
    }

    $color: map-get($color-map, $hue-key);
    $opacity: if(opacity($color) < 1, opacity($color), $opacity);

    @return rgba($color, $opacity);
}


@mixin create-custom-classes($theme, $components) {
    @include create-overrides($theme);
    @include text-overrides($theme);
    @include create-components($components);
}


/* Custom components */
@mixin create-components($components) {
/*     mat-card-header {
        background: map-get($components, subheader);
    } */

    .menu-link .menu-link-text {
        color: map-get($components, text);

        &:hover {
            font-weight: bold;
            color: darken(map-get($components, text), 30%);
        }
    }

    .mat-tab-label, .mat-tab-link {
        font-family: 'Source Sans Pro', sans-serif !important;
    }

    mat-card {
        border: 1px solid map-get($components, border);
    }

    mat-card .mat-card-title {
        color: map-get($components, header);
    }

    .jh-nav-top {
        border-bottom: 0.5px solid rgba(0,0,0,.10);
        background: map-get($components, headercolor);

        color: map-get($components, headerfontcolor);
    }

    .main-title .section-title {
        color: map-get($components, YeeHro CRM_text);
    }

    .logo-toggle {
        .toggler.sidebar-pinned {
            color: map-get($components, altaccent);
        }
    
        .toggler:hover {
            color: map-get($components, altaccent);
        }
    }

    .actions-toggle-button {
        color: map-get($components, header);

        &.mat-button-toggle-disabled {
            background-color: #e0e0e0;
        }
    }


    .mat-tab-label {
        background-color: #fff;
        margin-right: 10px;
        // border: 1px solid rgba(0, 0, 0, 0.2);
        border: 1px solid map-get($components, altaccent);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        font-size: 0.75rem;
    }

    .no-margins .mat-tab-label {
        margin: 0;
    }

    .mat-tab-label.mat-tab-label-active {
        color: map-get($components, header) !important;
    }    

    .borderless-tabs {
        .mat-tab-label {
            border: 0 !important;
            background-color: #fff;
            border: 2px solid map-get($components, altaccent);
        }

        .mat-tab-label.mat-tab-label-active {
            color: rgba(0, 0, 0, 0.8) !important;
        }

        .mat-ink-bar {
            height: 4px;
        }
    }    

    .borderless-tabs.no-pagination {
        .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
            display: none;
        }

        .mat-tab-label {
            width: auto;
            padding: 0;
            margin: 0;
            min-width: 110px;
            font-size: 13px;
        }

        .spread-wrapper .mat-tab-label {
            min-width: 100px !important;
        }
    }


    .fixed-side-nav {
        // background-color: $accent-color;

        background-color: #fff;
        border-right: 1px solid map-get($components, altaccent);

        .module-decor {
            background: map-get($components, altaccent);
            color: map-get($components, header);
        }    

        .module-decor.custom-icon {
            background: transparent;
        }
        
        .menu-link.is-active {
            background: map-get($components, altaccent);
        }
    }


    .mat-list-item.is-active {
        background: map-get($components, altaccent);
    }


    .colorbox-label {
        color: #78909c !important;
        font-family: 'Poppins', sans-serif !important;
    }

    .search-bar {
        border-bottom: 2px solid map-get($components, colorbox3);
    }

    .colorbox-data {
        color: #515365 !important;
        font-size: 30px !important;
        margin-top: 0 !important;
        margin-bottom: 5px !important;
    }


    app-colorbox:nth-of-type(1) .colorbox {
        /* background: map-get($components, colorbox1); */
        background: #fff;

        .colorbox-icon-area .mat-icon.material-icons {
            color: map-get($components, colorbox1);
        }
    }

    app-colorbox:nth-of-type(2) .colorbox {
        /* background: map-get($components, colorbox2); */
        background: #fff;

        .colorbox-icon-area .mat-icon.material-icons {
            color: map-get($components, colorbox2);
        }
    }

    app-colorbox:nth-of-type(3) .colorbox {
        /* background: map-get($components, colorbox3); */
        background: #fff;

        .colorbox-icon-area .mat-icon.material-icons {
            color: map-get($components, colorbox3);
        }
    }

    app-colorbox:nth-of-type(4) .colorbox {
        /* background: map-get($components, colorbox4); */
        background: #fff;

        .colorbox-icon-area .mat-icon.material-icons {
            color: map-get($components, colorbox4);
        }
    }

    app-colorbox .colorbox-row .colorbox-icon-area:after {
        background-color: #fff !important;
    }

    .top-nav {
        border-bottom: 1px solid map-get($components, border);
    }
}

@mixin text-overrides($theme) {
    $alt-text-color: md-color(map-get($theme, foreground), secondary-text);

    $custom-typography: mat.define-typography-config($font-family: 'Source Sans Pro');

    .ss-field-label,
    .ss-field-label-med,
    .ss-group-label,
    .ss-group-label-med {
        color: $alt-text-color;
    }
}

@mixin create-overrides ($theme) {
    // White or black background.
    $background-color: md-color(map-get($theme, background), card);
    $alt-background-color: md-color(map-get($theme, background), app-bar);
    // Primary color specified to theme.
    $primary-color: md-color(map-get($theme, primary));
    // Accent color specified to theme.
    $accent-color: md-color(map-get($theme, accent));

    .theme-primary-color {
        color: $primary-color;
    }

    .highlighted-link-field {
        font-weight: 400;
        text-decoration: none;
        color: $primary-color;
        stroke: $primary-color;
        cursor: pointer;
    }
    .highlighted-link-field:hover {
        text-decoration: underline;
        color: darken($primary-color, 10%);
    }

    .dashboard-outer-wrapper > header {
        background: $primary-color !important;
        color: $alt-background-color !important;
    }

    .ownership-status .material-icons {
        color: $primary-color;
    }


    .mat-button-toggle {
        background: $primary-color;
        color: #fff;
    }

    .full-page-calendar {
        .mat-button-toggle-checked {
            background: $primary-color;

            button {
                color: #fff !important;
            }
        }
    }

    .feed-mode-toggle-group {
        width: 100%;

        .feed-mode-button.mat-button-toggle {
            width: 50%;
            color: rgba(0, 0, 0, 0.87);
            background: white;

            button {
                color: rgba(0, 0, 0, 0.87) !important;
            }

            &.mat-button-toggle-checked {
                background: $primary-color;
                color: #fff;

                button.mat-button-toggle-button {
                    color: #fff !important;
                }

                .mat-button-toggle-focus-overlay {
                    border-bottom: unset !important;
                }
            }
        }
    }

    .default {
        background: #fff;
        color: $primary-color;
    }


    .record-chooser:hover {
        color: darken($primary-color, 10%);
    }

    .primary-text {
        color: $primary-color;
    }

    .grid-page-wrapper .table-wrapper a.link-name {
        color: $primary-color;
    }

    .grid-page-wrapper {
        .active-layout {
            background: rgba($primary-color, 0.1);
        }

        .action-col {
            .mat-icon:hover, .mat-checkbox:hover .mat-checkbox-frame {
                color: $primary-color;
            }

            .mat-checkbox:hover .mat-checkbox-frame {
                border-color: $primary-color;
            }
        }
    }

    .preview-wrapper .preview-header {
        background: $primary-color;
    }


    .new-profile .nav-item {
        svg path {
            fill: #b5b5c3 !important;
        }
    }

    .new-profile .nav-item:active, .new-profile .nav-item:focus, .new-profile .nav-item.is-active, .new-profile .nav-item:hover {
        // background-color: $primary-color !important;
        // color: #fff;
        background: #f3f6f9;
        color: $primary-color !important;
        font-weight: bold;

        .nav-link, .material-icons {
            color: $primary-color;
        }

        svg path {
            fill: $primary-color !important;
        }
    }

/*     .icon-container:hover,
    .selected .mat-list-item,
    mat-nav-list .mat-list-item.mat-list-item-focus, mat-nav-list .mat-list-item:hover {
        background: $primary-color;
    } */

    .ui-datatable tbody tr.ui-datatable-even {
        background: $alt-background-color;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
        color: $accent-color;
    }

    .navbar-inner-wrapper .notifier:hover {
        color: $primary-color;
    }

    .navbar-inner-wrapper .header-menu-trigger:hover {
        color: $primary-color;
    }

    .profile-wrapper .main-content .profile-header {
        background-color: $primary-color;
    }

    .fixed-side-nav {
        // background-color: $accent-color;

        background-color: #fff;

        // md-color(map-get($theme, altaccent))

        .menu-items-nav ul.menu-nav li.menu-item a.menu-link {
            color: $primary-color;
    
            .material-icons {
                // color: #3936e0;
                color: $primary-color;
            }
        }    
    }

    .altbg,
    .background,
    .top-nav,
    .ui-datatable thead th,
    .ui-widget-content {
        background: #eee;
    }


    #keypad-div .keypad-back,
    #keypad-div .keypad-clear,
    #keypad-div .keypad-close,
    #keypad-div .keypad-enter,
    #keypad-div .keypad-shift,
    #keypad-div .keypad-spacebar,
    .circled-task-letters,
    .circled-task-number,
    .ss-drawer .mdl-layout-title,
    .ss-nav-top,
    .mat-checkbox-checked.mat-primary .mat-checkbox-background,
    .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
    .mat-chip.mat-chip-selected:not(.md-basic-chip).mat-primary,
    .mat-input-underline .mat-input-ripple,
    .mat-progress-bar-fill::after,
    .ui-accordion .ui-accordion-header,
    .ui-accordion .ui-accordion-header.ui-state-active,
    .ui-accordion .ui-accordion-header.ui-state-active a,
    .ui-accordion .ui-accordion-header.ui-state-active span,
    .ui-button,
    .ui-chkbox .ui-chkbox-box.ui-state-active,
    .ui-datepicker thead tr,
    .ui-datepicker-buttonpane > button,
    .ui-draggable-dragging.ui-state-default,
    .ui-fieldset .ui-fieldset-legend .ui-fieldset-toggler,
    .ui-menu .ui-menu-list li .blue-icon-circle,
    .ui-panelmenu .ui-panelmenu-header,
    .ui-radiobutton .ui-radiobutton-box .fa-circle,
    .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-number,
    .ui-widget-header,
    [md-fab].md-primary,
    [md-mini-fab].md-primary,
    [md-raised-button].md-primary,
    mat-ink-bar,
    mat-slide-toggle.mat-primary.mat-checked:not(.md-disabled) .mat-slide-toggle-thumb,
    mat-toolbar.mat-primary,
    .ui-paginator {
        background-color: $primary-color;
    }

    .fc .fc-button-group .ui-state-active,
    .mat-checkbox-checked.mat-accent .mat-checkbox-background,
    .mat-checkbox-indeterminate.md-accent .mat-checkbox-background,
    .mat-chip.md-chip-selected:not(.mat-basic-chip).mat-accent,
    .mat-input-underline .mat-input-ripple.md-accent,
    .mat-radio-inner-circle,
    .mat-slider-thumb,
    .mat-slider-thumb-label,
    .mat-slider-track-fill,
    .timeline-frame .timeline-event.ui-state-active,
    .ui-accordion .ui-accordion-header.ui-state-active,
    .ui-button.pink-btn,
    .ui-button.secondary,
    .ui-buttonset .ui-state-active,
    .ui-datatable thead th.ui-state-active,
    .ui-datatable thead th.ui-state-highlight,
    .ui-datatable ttr.ui-datatable-even.ui-state-highlight,
    .ui-datatable ttr.ui-state-highlight,
    .ui-datepicker tbody td a.ui-state-active,
    .ui-datepicker tbody td.ui-datepicker-today a.ui-state-active,
    .ui-datepicker tbody td.ui-datepicker-today span.ui-state-active,
    .ui-datepicker-buttonpane > button.pink-btn,
    .ui-datepicker-buttonpane > button.secondary,
    .ui-dropdown-panel .ui-dropdown-item.ui-state-highlight,
    .ui-fileupload .ui-fileupload-buttonbar .ui-button,
    .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-handle,
    .ui-listbox .ui-listbox-item.ui-state-highlight,
    .ui-notificationbar,
    .ui-overlaypanel .ui-overlaypanel-close,
    .ui-panelmenu .ui-panelmenu-header.ui-state-active,
    .ui-picklist li.ui-picklist-item.ui-state-highlight,
    .ui-scrollpanel .ui-scrollpanel-drag,
    .ui-selectbooleanbutton.ui-state-active,
    .ui-selectmanymenu .ui-selectlistbox-item .ui-chkbox .ui-chkbox-box.ui-state-active,
    .ui-slider .ui-slider-handle,
    .ui-slider .ui-slider-range,
    .ui-state-active,
    .ui-state-highlight,
    .ui-togglebutton.ui-state-active,
    .ui-tree .ui-tree-droppoint.ui-state-hover,
    .ui-tree.ui-tree-horizontal .ui-treenode-content.ui-state-highlight,
    .ui-treetable tbody .ui-treetable-row.ui-state-highlight,
    .ui-treetable thead tr th.ui-state-active,
    [md-fab],
    [md-fab].mat-accent,
    [md-mini-fab],
    [md-mini-fab].mat-accent,
    [md-raised-button].mat-accent,
    mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb,
    mat-toolbar.mat-accent,
    ui-datepicker tbody td span.ui-state-active {
        background-color: $accent-color;
    }

    .mat-input-placeholder.mat-focused,
    .mat-inputfield input.ui-state-filled ~ label,
    .ui-fieldset .ui-fieldset-legend,
    .ui-tabmenu .ui-tabmenu-nav > .ui-tabmenuitem.ui-state-active a,
    .ui-tabmenu .ui-tabmenu-nav > .ui-tabmenuitem.ui-state-active a .fa,
    .ui-tabview .ui-tabview-nav > li.ui-state-active a,
    [mat-button].mat-primary,
    [mat-icon-button].mat-primary,
    mat-icon.mat-primary,
    mat-option.mat-selected,
    mat-select:focus:not(.mat-select-disabled) .mat-select-arrow,
    mat-select:focus:not(.mat-select-disabled) .mat-select-trigger {
        color: $primary-color;
    }

    .mat-input-placeholder.mat-float.mat-focused .mat-placeholder-required,
    .mat-input-placeholder.mat-focused.mat-accent,
    .ui-growl .ui-growl-item .ui-growl-icon-close,
    .ui-rating .ui-rating-star.ui-rating-star-on a,
    .ui-tabview .ui-tabview-nav > li.ui-state-active > .fa-close,
    [mat-button].mat-accent,
    [mat-icon-button].mat-accent,
    input.mat-input-element:-webkit-autofill+.mat-input-placeholder .mat-placeholder-required,
    mat-icon.mat-accent {
        color: $accent-color;
    }

    mat-progress-circle path,
    mat-progress-spinner path,
    mat-spinner path,
    a {
        stroke: $primary-color;
    }

    .mat-flat-button.mat-default {
        background-color: #e2e2e2;
    }    

    .ui-chkbox .ui-chkbox-box.ui-state-active,
    .ui-chkbox .ui-chkbox-box.ui-state-focus,
    .ui-datepicker .ui-timepicker-div .ui_tpicker_time input.ui-state-focus,
    .ui-inputtext.ui-state-focus,
    .ui-radiobutton .ui-radiobutton-box.ui-state-active {
        border-color: $primary-color;
    }

    .mat-radio-checked .mat-radio-outer-circle,
    .timeline-frame .timeline-event.ui-state-active,
    .ui-tabmenu .ui-tabmenu-nav > .ui-tabmenuitem.ui-state-active,
    .ui-tabview .ui-tabview-nav > li.ui-state-active {
        border-color: $accent-color;
    }

    .ui-datatable thead th.ui-state-active,
    .ui-datatable thead th.ui-state-highlight {
        border-top-color: $accent-color;
    }

    .ui-panel .ui-panel-titlebar,
    .ui-tree-draghelper,
    .ui-widget-header,
    mat-select:focus:not(.mat-select-disabled) .mat-select-trigger {
        border-bottom: 1px solid $primary-color;
    }

    .ui-datepicker tbody td.ui-datepicker-today a,
    .ui-datepicker tbody td.ui-datepicker-today span {
        border: 1px solid $accent-color;
    }

    .ui-wizard .ui-wizard-step-titles > li.ui-state-highlight {
        border-bottom: 2px solid $accent-color;
    }

    .ui-datatable thead.ui-datatable-thead th,
    .ui-datatable thead.ui-datatable-thead th:first-child,
    .ui-datatable thead.ui-datatable-thead th:last-child {
        border-color: $background-color;
    }
}

.ui-paginator-bottom.ui-paginator.ui-widget {
    border-bottom: 0 !important;
    border-top: 1px solid #dee2e6 !important;
}


.default {
    .jh-nav-top {
        color: #3e3e3e;
        background-image: url(/uploads/system_images/header-crazy.png);
    }

    .author-title .user-name {
        color: #3e3e3e;
    }
}