.slide-wrapper {
  overflow-x: hidden;
}

.slide-panel {
  display: none;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  // -webkit-transition: opacity 0.6s ease-out;
  // transition: opacity 0.6s ease-out;

  .arrow {
    width: 25px;
  }

  .panel-fields {
    width: 100%;
    min-height: 285px;
  }
}

.carousel-control {
  border-radius: 50%;
  height: 40px;
  width: 45px;

  background: rgba(0, 0, 0, 0.28);
  color: #fff;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;

  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.slide-panel.active-panel {
  // display: flex;
  display: block;
  opacity: 100;
  overflow-x: hidden;

  .carousel-control {
    display: flex;
  }
}

.carousel-control:hover {
  background: rgba(0, 0, 0, 0.8);
  color: #aaaaaa;
}

.active-panel {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

.inactive-panel {
  animation: fadeOut ease 1s;
  -webkit-animation: fadeOut ease 1s;
  -moz-animation: fadeOut ease 1s;
  -o-animation: fadeOut ease 1s;
  -ms-animation: fadeOut ease 1s;
}


.paginator-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5px;

  .paginator {
    display: flex;
    align-items: center;

    .panel-tracker {
      color: rgba(0, 0, 0, 0.54);
      font-size: .75rem;
      padding: 0 10px;
    }

    .arrow {
      display: flex;
      cursor: pointer;
      border: 1px solid transparent;
      border-radius: 5px;

      &:hover {
        background: #e6ecf582;
        border: 1px solid #e6ecf5;
      }
    }
  }
}

  
@keyframes slide-in {
  100% { 
    transform: translateX(0%); 
  }
}

@-webkit-keyframes slide-in {
  100% { 
    -webkit-transform: translateX(0%); 
  }
}
  
@keyframes slide-out {
  0% { 
    transform: translateX(0%); 
  }
  100% { 
    transform: translateX(-100%); 
  }
}

@-webkit-keyframes slide-out {
  0% { 
    -webkit-transform: translateX(0%); 
  }
  100% { 
    -webkit-transform: translateX(-100%); 
  }
}



.fade-in {
  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 10s;
  -moz-animation: fadeIn ease 10s;
  -o-animation: fadeIn ease 10s;
  -ms-animation: fadeIn ease 10s;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  10% {
    display: flex;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  10% {
    display: flex;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  10% {
    display: flex;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  10% {
    display: flex;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  10% {
    display: flex;
  }
  100% {
    opacity:1;
  }
}


.fade-out {
  animation: fadeOut ease 10s;
  -webkit-animation: fadeOut ease 10s;
  -moz-animation: fadeOut ease 10s;
  -o-animation: fadeOut ease 10s;
  -ms-animation: fadeOut ease 10s;
}
@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
    display: none;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
    display: none;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
    display: none;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
    display: none;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
    display: none;
  }
}