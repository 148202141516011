.search-content {
    min-width: 300px;
    padding: 25px;

    .search-footer .btn-group {
        display: flex;
    }

    .fifty-fifty-btns button {
        width: 50%;
    }
}

.search-btn.active-search .mat-stroked-button {
    border: 1px solid #ff000059 !important;
    box-shadow: 0px 0px 4px 7px #e3e1e3 !important;
    -webkit-box-shadow: 0px 0px 4px 7px #e3e1e3 !important;
}

// Only way to stop ngx-simplebar scrollbar bleeds through on fixed preview-panel. Do only when opened.
ngx-simplebar {
    &:has(.grid-page-wrapper .main-contents .preview-panel.panel-fixed.open-preview-panel) {
        .simplebar-track.simplebar-vertical {
            visibility: hidden !important;
        }
    }
}

.grid-page-wrapper {
    min-height: 90vh;

    &.unset-height {
        height: unset !important;
    }

    .grid-view-container, .split-75-25 {
        min-height: 90vh;
    }

    .card.main-contents, .card.info-panel {
        margin: 5px;
    }

    .split-75-25 {
        display: flex;
        flex-direction: column;

        .info-panel {
            position: relative;
            display: none; 
            margin: 5px 0;     

            .info-panel-inner {
                background-color: #fff;    

                margin: 0;
                padding: 5px;
            }
        }

        .mat-form-field-infix {
            width: 100%;
        }
    }

    .main-contents {
        position: relative;
        overflow-x: hidden;
        flex-grow: 2;

        .preview-panel {
            position: absolute;
            background: #f9f9f9;
            height: 100%;         
            box-shadow: -5px 0px 5px -3px #e3e1e3; -webkit-box-shadow: -5px 0px 5px -3px #e3e1e3;

            transition-property: right;
            transition-duration: 0.4s; -webkit-transition-duration: 0.4s; -moz-transition-duration: 0.4s; -o-transition-duration: 0.4s;
            transition-delay: .0s, .3s, 0s;
            transition-timing-function: ease-out;
            max-width: 1100px;

            &.panel-fixed {
                position: fixed;
                height: calc(100vh - 79px) !important;
                min-height: calc(100vh - 79px);
                overflow-y: auto;

                .mat-drawer-container {
                    height: auto; // Some panel profiles show scroll even when no overflow because of height 100% in material-design-overrides.scss.
                }
            }

            &.make-fixed-panel {
                position: fixed;
                height: calc(100vh - 70px) !important;
                min-height: calc(100vh - 70px);
                overflow-y: auto;
            }
        }

        .preview-panel.closed-preview-panel {
            right: -100%;
            top: 0;
        }

        .preview-panel.open-preview-panel {
            right: 0;
            top: 0;
        }

        .preview-panel.panel-fixed.closed-preview-panel {
            right: -100%;
            top: 79px;
        }

        .preview-panel.panel-fixed.open-preview-panel {
            right: 0;
            top: 79px;
        }

        .preview-panel.make-fixed-panel.closed-preview-panel {
            right: -100%;
            top: 70px;
        }

        .preview-panel.make-fixed-panel.open-preview-panel {
            right: 0;
            top: 70px;
        }

        .pinned-jobs-wrapper .preview-panel {
            position: relative !important;
        }
    }

    .card-heading {
        display: flex;
        flex-direction: column;
        // padding: 10px 20px 0 20px;
        padding: 0 20px 0 20px;

        .advanced-search-input {
            cursor: pointer;
        }

        .left {
            display: flex;
            justify-content: space-between;

            flex-direction: row;
            flex-wrap: wrap;
            flex-grow: 2;

            .module-title {
                padding-right: 10px;

                h3 {
                    // font-weight: 500;
                    font-weight: 600;
                    font-size: 1.5rem;
                }                
            }

            .grid-actions {
                display: flex;
                padding-right: 10px;
                justify-content: flex-end;
            }


            .grow-2 {
                flex-grow: 2;
                padding-right: 10px;
            }            
        }

        .right {
            justify-self: flex-end;
            justify-content: flex-end;
            padding-right: 10px;
            margin-bottom: 10px;
        }   
        
        .mat-form-field {
            width: 100%;
        }

        .create-btn-wrapper {
            padding-left: 5px;          
        }

        .square-icon-button.mat-flat-button, .square-icon-button.mat-stroked-button {
            width: 45px;
            min-width: 45px;
            line-height: 45px;
            padding: 0 1px 0 0;
        }         
    }


    .table-wrapper {
        .header-col-wrapper {
            display: flex;
        }

        .mat-cell, .mat-footer-cell {
            font-size: 14px;
            padding: 5px 10px;
        }

        a.link-name {
            text-decoration: none;
            font-weight: 600;
            color: #770a0a;
        }

        a.link-name:hover {
            text-decoration: underline;
        }        

        .action-col {
            display: flex;
            align-items: center;

            .icon-size {
                padding-right: 20px;
                margin-top: 2px;

                .mat-table .mat-checkbox-inner-container {
                    height: 15px;
                    width: 15px;
                }
            }
            

            .mat-icon-button {
                width: 24px;
                height: 30px;
                line-height: 30px;

                .material-icons {
                    font-size: 21px;
                }      
                
                &.max-25 {
                    line-height: unset;
                    height: auto;
                }
            }
        }

        .justified-center {
            justify-content: center;
        }

        .link-trigger {
            flex: 0 0 30px;
            margin-right: 10px;

            .btn-flex {
                padding: 5px 16px;
            }
        }
    }

    .module-dashboard {
        .card-heading.grid-filter-header {
            justify-content: space-between;
            padding-top: 15px;
            
            .left {
                flex-grow: unset;
            }   
            
            .dash-header-center {
                margin-left: -150px;

                h1 {
                    margin: 0;
                }
            }
        }
    }
}

.tasks-board.module-dashboard {
    .card-heading.grid-filter-header {
        justify-content: space-between;
        padding-top: 15px;

        .left {
            flex-grow: unset;
        }   

        .dash-header-center {
            margin-left: 0;

            h1 {
                margin: 0;
            }
        }
    }
}

.grid-container-page-wrapper {
    .smart-table-wrapper {
        .header-col-wrapper {
            display: flex;
        }

        .mat-cell, .mat-footer-cell {
            font-size: 14px;
            padding: 0 10px;
        }

        .mat-cell:first-of-type, .mat-footer-cell:first-of-type {
            padding-left: 24px;
        }

        a.link-name {
            text-decoration: none;
            font-weight: 600;
            color: #770a0a;
        }

        a.link-name:hover {
            text-decoration: underline;
        }        

        .action-col {
            display: flex;
            align-items: center;

            .icon-size {
                padding-right: 20px;
                margin-top: 2px;

                .mat-table .mat-checkbox-inner-container {
                    height: 15px;
                    width: 15px;
                }
            }

            .mat-icon-button {
                width: 24px;
                height: 30px;
                line-height: 30px;

                .material-icons {
                    font-size: 21px;
                }      
                
                &.max-25 {
                    line-height: unset;
                    height: auto;
                }
            }
        }

        .justified-center {
            justify-content: center;
        }

        .link-trigger {
            flex: 0 0 30px;
            margin-right: 10px;

            .btn-flex {
                padding: 5px 16px;
            }
        }
    }
}

.custom-relationship-grid-wrapper {
    .card-heading {
        .header-btns {
            display: flex;
            align-items: center;

            .custom-relationship-btn {
                width: 40px;
                min-width: 40px;
                line-height: 35px;
                padding: 0 1px 0 0;
            }

            .search-btn {
                margin-left: 10px;

                .custom-relationship-btn {
                    height: 37px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }


    .smart-table-wrapper {
        .mat-cell, .mat-footer-cell {
            font-size: 14px;
            padding: 0 5px;
        }

        .mat-header-cell:first-of-type {
            padding-right: 5px;
        }

        .mat-cell:first-of-type, .mat-footer-cell:first-of-type {
            padding-left: 24px;
        }

        a.link-name {
            text-decoration: none;
            font-weight: 600;
            color: #770a0a;
        }

        a.link-name:hover {
            text-decoration: underline;
        } 

        .action-col {
            display: flex;
            align-items: center;

            .icon-size {
                padding-right: 20px;
                // margin-top: 2px;
            }

            .mat-icon-button {
                width: 24px;
                height: 30px;
                line-height: 30px;

                &.max-25 {
                    line-height: inherit;
                    height: auto;
                }
            }
        }
    }

    .preview-panel {
        position: fixed;
        background: #f9f9f9;
        height: calc(100vh - 70px) !important;
        min-height: calc(100vh - 70px);
        box-shadow: -5px 0px 5px -3px #e3e1e3; -webkit-box-shadow: -5px 0px 5px -3px #e3e1e3;

        transition-property: right;
        transition-duration: 0.4s; -webkit-transition-duration: 0.4s; -moz-transition-duration: 0.4s; -o-transition-duration: 0.4s;
        transition-delay: .0s, .3s, 0s;
        transition-timing-function: ease-out;
        max-width: 1100px;
        // overflow: hidden;
        overflow-y: auto;
    }

    .preview-panel.closed-preview-panel {
        right: -100%;
        top: 70px;
    }        

    .preview-panel.open-preview-panel {
        right: 0;
        top: 70px;
    }
}

.square-icon-button.mat-button {
    width: 40px;
    min-width: 40px;
    line-height: 35px;
    padding: 0 1px 0 0;
} 


.no-min-width.mat-button {
    min-width: auto !important;
}

.no-padding.mat-button {
    padding: 0 !important;
}

.no-placeholder.mat-form-field-should-float .mat-form-field-label-wrapper {
    display: none;
}


@media (min-width:681px)  {
    .grid-page-wrapper {
        .card-heading {
            flex-direction: row;
            padding: 15px 20px 0 20px;

            .right {
                margin-bottom: unset;
            }

            .left {
                flex-direction: row;
                justify-content: flex-start;
            }

            .square-icon-button.mat-flat-button, .square-icon-button.mat-stroked-button {
                width: 40px;
                min-width: 40px;
                line-height: 35px;
            } 
        }

        .table-wrapper {
            .mat-cell:first-of-type, .mat-footer-cell:first-of-type {
                padding-left: 24px !important;
            }
        }
    }
}


@media (min-width:681px) and (max-width:1000px)  {
    .grid-page-wrapper {
        .table-wrapper {
            overflow-x: scroll;
        }
    }
}


@media (min-width:1225px)  {
    .grid-page-wrapper {
        .split-75-25 {
            // display: flex;
            flex-direction: row;

            .info-panel {
                display: block;
                width: 25%;
            }
        }

        .card-heading {
            flex-direction: row;
            align-items: center;

            .left {
                flex-direction: row;
                align-items: center;

                .grid-actions {
                    justify-content: unset;

                    .square-icon-button {
                        margin-right: 5px;
                    }
                }
            }

            .right {
                padding-right: 0;
            }

            .create-btn-wrapper {
                padding-left: 10px;          
            }
        }

        .table-wrapper {
            .mat-cell, .mat-footer-cell {
                padding: 0 10px !important;
            }
        }
    }

    .tasks-board.module-dashboard {
        .card-heading.grid-filter-header {
            .dash-header-center {
                margin-left: -150px;
            }
        }
    }
}

.full-width-field .mat-form-field-infix {
    max-width: 100% !important;
}

.custom-grid-header {
    .custom-grid-mobile-wrapper {
        display: none;
        justify-content: space-between;
        align-items: center;
        padding: 25px 0;
        border-bottom: 1px solid;
        border-bottom-color: rgba(0, 0, 0, 0.12);

        .custom-grid-mobile-sort-tools {
            display: none;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: 50%;
            padding: 0 5px 0 0;

            .sort-field {
                min-width: 110px;
            }

            .sort-direction {
                width: 100%;
                padding: 20px 5px 5px;

                .mat-radio-button:first-of-type {
                    margin-right: 10px;
                }
            }
        }

        .custom-grid-mobile-right {
            display: flex;
            justify-self: flex-end;
            justify-content: flex-end;
            margin-top: 0;
            padding-right: 10px;
        }
    }
}

@media (max-width: 680px) {
    .custom-grid-header {
        .right {
            display: none !important;
        }

        .custom-grid-mobile-wrapper {
            display: flex;

            .custom-grid-mobile-sort-tools {
                display: flex;
            }
        }
    }
}