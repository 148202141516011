.jw.sidenav-content {
    width: 100%;
    overflow-y: auto;

    .search-content {
        padding-bottom: 20px;

        h1, h2, h3, h4, h5, h6 {
            color: #515365;
            letter-spacing: .5px;
            font-weight: bold;
        }

        .bd-title {
            margin-bottom: 35px;
        }

        .flex-grid.space-between {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .flex-field {
                min-width: 150px;

                .mat-form-field-appearance-outline .mat-form-field-infix {
                    padding: 1em 0!important;
                    border-top: .84375em solid transparent!important;
                }
            }
        }

        .search-footer {
            min-height: 225px;
            border-top: 1px solid #e5e5e5;
            padding-top: 50px;

            .button-group {
                display: flex;
                width: 100%;
                position: relative;
                vertical-align: middle;
                margin-top: 10px;

                button {
                    width: 49%;
                    position: relative;
                    flex: 1 1 auto;
                    font-size: 13px;
                    cursor: pointer;
                    padding: 10px 20px;
                    margin: 0 5px;
                    font-family: roboto,Segoe UI,Helvetica Neue,Arial,sans-serif;

                    display: inline-block;
                    font-weight: 400;
                    color: #212529;
                    text-align: center;
                    vertical-align: middle;
                    user-select: none;
                    line-height: 1.5;
                    border-radius: .25rem;
                    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }
            }

            .btn-group>.btn-group:not(:first-child), .btn-group>.btn:not(:first-child) {
                margin-left: -1px;
            }

            .btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }        
    }

    .container-fluid {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
}

mat-sidenav.mat-drawer.mat-sidenav.grid-sidenav-form.mat-drawer-opened {
    max-width: 100%;
}

@media only screen and (min-width: 769px) {
    mat-sidenav.grid-sidenav-form.custom {
        min-width: 400px !important;
    }
}