.stacked-profile {
    display: block;

    main.profile-main {
        display: block;

        .profile-info {
            display: block;

            .info-header-card {
                min-height: unset;
            }
        }

        .matching-jobs-outer {
            margin-top: -10px;
        }

        .full-info-wrapper {
            padding: 3px 3px 0 0;

            .info-tab-contents {
                display: flex;
                flex-wrap: wrap;
    
                .field-row {
                    flex: 0 50%;
                    min-width: 50%;
                }
            }
        }
    }

    .side-panel-right {
        flex: 0 25%;
    }
}

.back-up-link {
    cursor: pointer;

    &:hover {
        color: #6fa8ff;
        text-decoration: underline;
    }
}

@media (min-width:750px)  {
    /* At a certain point, we want 3 columns (profile left/right and the widget sidebar) Sidebar will be on bottom if mobile */
    .stacked-profile {
        main.profile-main {
            .profile-info {
                display: flex;

                .info-col {
                    flex: 0 30%;
                }

                .profile-full-info {
                    flex: 0 70%;
                }
            }

            // flex: 0 75%;
            // display: flex; /* Now enough space for the profile column to have 2 columns */

            // .lead-info {
            //     flex: 0 30%;
            // }

            // .main-content {
            //     flex: 0 70%;
            // }
        }
    
        .side-panel-right {
            flex: 0 25%;
        }
    }
}

@media (min-width:1200px)  {
    .stacked-profile {
        display: flex; /* Now enough room for 3rd column. Move widget panel to the far right */
    
        main.profile-main {
            flex: 0 75%;
            // display: flex;
        }
    
        .side-panel-right {
            flex: 0 25%;
        }
    }

    .stacked-profile.twenty-five-seventy-five {
        display: block;
    
        .side-panel-right {
            display: none;
        }
    }
}




.profile-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    padding: 15px;

    .left-col {
        // width: 100%;
        margin-bottom: 25px;
    }

    .center-col {
        // width: 100%;
        padding: 10px;
        border: 1px solid #e6ecf5;
        border-radius: 5px;
        margin: 0 3px 25px 3px;
    }

    .right-col {
        // width: 100%;
        padding: 10px;
        border: 1px solid #e6ecf5;
        border-radius: 5px;
        margin: 0 3px 30px 3px;
    }
}


.two-column {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    padding: 15px;

    .left-col {
        // width: 25%;
        margin-bottom: 25px;
    }

    .main-content {
        // width: 75%;
        // padding: 0 5px;

        padding: 10px;
        border: 1px solid #e6ecf5;
        border-radius: 5px;
        margin: 0 3px 45px 3px;
    }
}


.info-header-card {
    min-height: 99%;
    margin: 3px;

    .bg-image {
        height: 135px;
    
        background-image: url(/uploads/system_images/lead_bg_1.jpg);
        background-size: cover;
    
        display: flex;
        align-items: center;
        justify-content: center;

        // .set-profile-image {
        //     z-index: 1;
        //     position: absolute;

        //     img {
        //         width: 100%;
        //         height: 115px;
        //         background-size: cover;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //     }
        // }

        .sub-menu {
            position: absolute;
            top: 5px;
            right: 5px;

            .sub-menu-btn.mat-primary {
                background-color: #7c21b2;
            }
        }
    
        .info-avatar {
            border: 2px solid #006064;
            border-radius: 50%;
            height: 65px;
            width: 65px;
            // z-index: 2;
        }
    }

    .info-title {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #757575;

        .upper-card-field {
            text-align: center;
            padding-top: 3px;
            padding-bottom: 10px;
            font-size: 16px;            
        }

        .info-name {
            font-size: 22px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.7);
        }

        .info-profession {
            text-align: center;
            padding-top: 3px;
            padding-bottom: 10px;
            font-size: 16px;
        }

        .info-address {
            text-align: center;
            font-size: 13px;
        }

        .ff-fields {
            display: flex;
            justify-content: space-between;
            padding: 10px;

            .ff-wrapper {
                display: flex;
                align-items: center;
                padding: 10px;
    
                .ff-icon {
                    padding-right: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            
                .ff-text {
                    font-size: 13px;
                    font-weight: 600;
                }            
            }            
        }

        .phone-assigned {
            display: flex;
            justify-content: space-between;
            padding: 2px 15px 10px;

            .widget-wrapper {
                padding: 10px;
                display: flex;
                align-items: center;

                .widget-icon {
                    padding-right: 3px;

                    .material-icons {
                        font-size: 21px;
                    }
                }

                .widget-text {
                    font-size: 13px;
                    font-weight: 600;
                }
            }

            .phone-number {
                color: #4db6ac;
                cursor: pointer;
            }

            .phone-number:hover {
                color: #3b8880;
                cursor: pointer;
            }
        }

        .quick-action-toolbar {
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            font-size: 14px;

            color: #4a4a4a;
            background: #fff;
            border-top: 1px solid #ececec;
            border-bottom: 1px solid #ececec;
            padding-top: 10px;

            .action-item.mat-flat-button {
                min-width: auto;
                padding: 0;
                text-align: center;
            }

            .rounded-icon {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin: 0 auto;
                border: 1px solid #dadfe6;
                display: flex;
                justify-content: center;
                align-items: center;   
                background: #ebf1f8; 
                
                .material-icons {
                    color: #556f92;
                    font-size: 21px;
                    display: flex;
                    justify-content: center;
                    align-items: center;                       
                }
            }

            .action-item-label {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                color: #0000008a
            }

            .action-item.mat-flat-button:hover {
                background: none;
                color: #000000c7;

                .rounded-icon {
                    border: 1px solid #00000075;
                }

                .action-item-label {
                    color: #000000c7;
                }
            }            
        }
    }

    .info-tabs {
        margin-top: 15px;
    }
}


.basic-profile-wrapper {
    .card-heading.profile-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .profile-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .record-title {
            color: #494850;
            font-size: 18px;
  
            h2 {
                margin-top: 5px;
            }
        }
  
        .actions {
            .square-icon {
                margin-right: 20px;
            }
  
            .toggle-inner {
                padding: 0 10px;
            }
            .other-action-trigger {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid black;
                border-radius: 5px;
            }
        }
    }
  }


.activiy-profile-wrapper.is-from-slideout {
    margin-top: 20px;

    .basic-profile-wrapper.card {
        border: none !important;
    }

    .card-heading.profile-header {
        // padding: 10px 10px 0 !important;
    }

    .card-body.outer-body-wrapper {
        padding: 15px 10px !important;
    }
}


.lower-card-fields {
    position: relative;

    .card-action-wrapper {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    
    .edit-details-wrapper {
        padding-bottom: 30px;
        
        .edit-header-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
        }
    }
    
    .info-tab-contents {
        padding: 15px;
    
        .field-row {
            padding-bottom: 25px;
            color: #000000ba;
    
            .field-label {
                font-size: 15px;
                padding-bottom: 7px;
            }
    
            .field-value {
                font-weight: 600;
            }
    
            .lead-status {
                font-size: 18px;
            }                
        }
    }
}


@media (min-width:1225px)  {
    .profile-wrapper {
        flex-direction: row;
        padding: unset;
    
        .left-col {
            width: 25%;
            margin-bottom: unset;
        }
    
        .center-col {
            width: 50%;
            padding: 0 5px;
            border: unset;
            border-radius: unset;
            margin: unset;
        }
    
        .right-col {
            width: 25%;
            padding: unset;
            border: unset;
            border-radius: unset;
            margin: unset;
        }
    }

    .two-column {
        flex-direction: row;
        padding: unset;
    
        .left-col {
            width: 25%;
            margin-bottom: unset;
        }
    
        .main-content {
            width: 75%;
            padding: 0 5px;

            border: unset;
            border-radius: unset;
            margin: unset;
        }
    }
}

.doc-preview {
    .basic-profile-wrapper {
        max-width: 100% !important;

        .process-id-instance-wrapper {
            max-width: 100% !important;
            max-height: 100vh !important;
        }
    }
}