.picker-layout-page {
    position: relative;

    .panel-toggle {
        position: absolute;
        top: 10px;
        left: 10px;
        height: 50px;
        width: 50px;
        padding: 2px 5px;
        border-radius: 10px;
        color: #fff;
        z-index: 9998;

        .panel-toggle-button {
            padding: 10px 0;
            opacity: .9;
        }

        .panel-toggle-button:hover {
            opacity: 1;
        }
    }

    .picker-container {
        position: absolute;
        top: 50px;
        left: 50px;
        min-height: 550px;
        background: #fff;
        z-index: 9999;
        border: 1px solid #e6ecf5;
        cursor: pointer;
        border-radius: 10px;

        transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

        overflow: hidden;

        .widgets-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;

            .drag-handle {
                cursor: move;
            }

            .close-wrapper {
                cursor: pointer;
            }

            .drag-handle:hover,
            .close-wrapper:hover {
                color: red;
            }
        }

        .available-fields-container {
            overflow: auto;
            height: 450px;
        }
    }

    .picker-container.expanded {
        width: 250px;
        display: block;
    }

    .picker-container.tucked {
        width: 1px;
        display: none;
    }
}

.info-header-card {
    height: 99%;
    margin: 3px;

    .bg-image {
        height: 115px;

        background-image: url(/uploads/system_images/lead_bg_1.jpg);
        background-size: cover;

        display: flex;
        align-items: center;
        justify-content: center;

        .info-card-avatar {
            border: 2px solid #006064;
            border-radius: 50%;
            height: 65px;
            width: 65px;
        }
    }

    .info-card-title {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #757575;

        .info-card-name {
            font-size: 22px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.7);
        }

        .info-card-profession {
            text-align: center;
            padding-top: 3px;
            padding-bottom: 10px;
            font-size: 16px;
        }

        .info-card-address {
            text-align: center;
            font-size: 13px;
        }

        .phone-assigned {
            display: flex;
            justify-content: space-between;
            padding: 2px 15px 10px;

            .widget-wrapper {
                padding: 10px;
                display: flex;
                align-items: center;

                .widget-icon {
                    padding-right: 3px;

                    .material-icons {
                        font-size: 21px;
                    }
                }

                .widget-text {
                    font-size: 13px;
                    font-weight: 600;
                }
            }

            .phone-number {
                color: #4db6ac;
                cursor: pointer;
            }

            .phone-number:hover {
                color: #3b8880;
                cursor: pointer;
            }
        }

        .quick-creates {
            display: flex;
        }

        .quick-action-toolbar {
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            font-size: 14px;

            color: #4a4a4a;
        }
    }

    .info-tabs {
        margin-top: 15px;
    }
}


.action-item.mat-flat-button {
    min-width: auto;
    padding: 0;
    text-align: center;
}

.rounded-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0 auto;
    border: 1px solid #dadfe6;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ebf1f8;

    .material-icons {
        color: #556f92;
        font-size: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.action-item-label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #0000008a
}

.action-item.mat-flat-button:hover {
    background: none;
    color: #000000c7;

    .rounded-icon {
        border: 1px solid #00000075;
    }

    .action-item-label {
        color: #000000c7;
    }
}

.title-edit {
    display: flex;
    align-items: center;
    justify-content: center;

    .qc-edit {
        margin-top: -5px;
    }
}

button.action-item {
    cursor: move;
    border-radius: 0;
}


.current-layout-container {
    .section-wrapper {
        background: #eee;
        padding: 0 15px 15px 15px;

        .section-header {
            align-items: center;

            .section-handle {
                cursor: move;
                color: #3f51b5;
                font-weight: 600;
                padding-right: 5px;
                padding-top: 3px;
            }

            .edit-label {
                cursor: pointer;
            }
        }

        .layout-section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            min-height: 30px;  
            background: #ddd;
            padding: 10px 15px;
            margin: 5px 0;
        }
    }

    .module-field {
        padding: 10px;
        margin: 5px;
        height: 30px;
        cursor: move;
        color: #3f51b5;
        display: flex;
        align-items: center;
        padding: 15px;
        border: 2px solid #3f51b5;
        font-weight: 700;
    }
}




.module-field {
    padding: 10px;
    background: white;
    margin: 5px;
    height: 30px;
    cursor: move;
    display: flex;
    align-items: center;
    justify-content: center;
}

.available-fields-container {
    padding: 0 10px 0 5px;

    .recycle-wrapper {
        border: 1px solid #ddd;
        border-radius: 5px;
        margin: 0 5px;

        .recycle-label {
            background: #f5f5f5;
            color: #888da8;
            padding: 10px;
        }

        .recycle-bin {
            margin: 5px;
            height: 100px;
            background: url('/uploads/system_images/recycle.png') no-repeat center;
        }
    }

    .module-field {
        height: 30px;
        padding: 10px;
        border: 1px solid #ddd;
    }

    .new-panel {
        cursor: pointer;
    }

    .new-panel:hover {
        background: #dddddd5c;
    }
}

.defined-layout-container {
    flex-grow: 2;
    padding: 0 15px;

    .layout-info.default-flex {
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
    }
}

.current-layout-container {
    .section-wrapper {
        background: #eee;
        padding: 0 15px 15px 15px;

        .section-header {
            align-items: center;

            .section-handle {
                cursor: move;
                color: #3f51b5;
                font-weight: 600;
                padding-right: 5px;
                padding-top: 3px;
            }

            .edit-label {
                cursor: pointer;
            }
        }

        .layout-section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            min-height: 30px;
            background: #ddd;
            padding: 10px 15px;
            margin: 5px 0;
        }
    }

    .module-field {
        padding: 10px;
        margin: 5px;
        height: 30px;
        cursor: move;
        color: #3f51b5;
        display: flex;
        align-items: center;
        padding: 15px;
        border: 2px solid #3f51b5;
        font-weight: 700;
    }
}

.code-output {
    margin-bottom: 10px;
}



.available-fields-list {
    display: block;
    min-height: 390px;

    .module-field {
        height: 30px;
        cursor: move;
        color: #3f51b5;
        display: flex;
        border: 2px solid #3f51b5;
        font-weight: 700;
    }
}

.module-field {
    text-align: center;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    // border-radius: 100px;
    border: 2px solid #ececec;
    background: #F7F7F7;
    transition: all .5s ease;
}

.module-field:active {
    -webkit-animation: wiggle 0.3s 0s infinite ease-in;
    animation: wiggle 0.3s 0s infinite ease-in;
    opacity: .6;
    border: 2px solid #000;
}

.module-field:hover {
    border: 2px solid gray;
    background-color: #e5e5e5;
}

.widget-section {
    min-height: 50px;
    background: #fff;
    margin: 5px 0;
    display: flex;
    flex-direction: column;
}

.drop-container .layout-section {
    min-height: 50px;
    background: #ddd;
    margin: 5px 0;

    .section-wrapper {
        padding: 5px;
    }
}

.drop-container {
    border: 2px dashed #888da8;
    border-radius: 5px;
    background: #fff;
    padding: 5px 10px;
    min-height: 50px;
}


.main-content .section-wrapper {
    margin-bottom: 10px;
}


.right-col .section-header {
    padding: 5px 0;
    align-items: center;

    .edit-label {
        margin-top: -5px;
        height: 25px;
    }

    .mat-icon-button {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }    
}


.ff-wrapper {
    padding: 10px;
    display: flex;
    align-items: center;
    
    .ff-icon {
        padding-right: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ff-text {
        font-size: 13px;
        font-weight: 600;
    }
}


@-webkit-keyframes wiggle {
    0% {
        -webkit-transform: rotate(0deg);
    }

    25% {
        -webkit-transform: rotate(2deg);
    }

    75% {
        -webkit-transform: rotate(-2deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
    }
}

@keyframes wiggle {
    0% {
        transform: rotate(-2deg);
    }

    25% {
        transform: rotate(2deg);
    }

    75% {
        transform: rotate(-2deg);
    }

    100% {
        transform: rotate(0deg);
    }
}